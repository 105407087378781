@use 'styles/new-design/mixins/media-queries' as media;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: var(--ds-neutral-900);
}

a {
  color: var(--ds-primary-500);
  cursor: pointer;
  text-decoration: underline;
}

a.no-underline {
  cursor: pointer;
  text-decoration: none !important;
}

*:not(.mdc-list-item__content)
  > a[href*='http']:not(.mat-mdc-button-base):not(.mat-mdc-list-item):not(
    .mat-mdc-menu-item
  ):not([href*='map']) {
  position: relative;
  padding-right: calc(0.8 * var(--ds-spacing));

  &::after {
    position: absolute;
    font-family: 'Material Icons';
    content: '\e89e';
    color: var(--ds-neutral-900);
    padding-left: calc(0.1 * var(--ds-spacing));
  }

  &.no-icon {
    padding-right: 0;
    &::after {
      display: none;
    }
  }
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, var(--ds-neutral-900));
}

strong {
  font-weight: 500;
}

h5:not([class*='default']),
.h5,
h6:not([class*='default']),
.h6 {
  line-height: 1;
}

h1:not([class*='default']),
.h1 {
  font-family: 'PalfingerHandelVersalien';
  @include media.media-xs {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

h2:not([class*='default']),
.h2 {
  font-family: 'PalfingerHandelVersalien';
  @include media.media-xs {
    font-size: 1.5rem;
  }
}

h3:not([class*='default']),
.h3 {
  font-family: 'PalfingerHandelVersalien';
  font-size: 1.25rem;
  @include media.media-xs {
    font-size: 1rem;
  }
}

h4:not([class*='default']),
.h4 {
  font-family: 'PalfingerHandelVersalien';
  @include media.media-xs {
    font-size: 0.875rem;
  }
}

h5:not([class*='default']),
.h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: inherit;

  @include media.media-xs {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
  }
}

h6:not([class*='default']),
.h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.65rem;
  color: var(--ds-neutral-500);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  &.no-palfinger-font {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: var(--ds-neutral-700);
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 70%;
}

small:not([class*='default']),
.small {
  font-size: 85%;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-bold {
  font-weight: 500;
}
